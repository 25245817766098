import React, { useEffect, useState } from 'react'
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs,query,orderBy,limit,startAfter,endBefore,where} from 'firebase/firestore/lite';
import { IoMdNotificationsOutline } from "react-icons/io";
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import {firebaseCredentials} from '../../config.js'
import ClipLoader from "react-spinners/ClipLoader";
import NotificationModal from '../modals/notification_modal.jsx';
import PageNotFound from './page_not_found.jsx';

 
let db;
let page =0;
let credentials;

const Home = () => {

    const[articles,setArticles] = useState([]);
    const[visible,setLastVisible] = useState(null);
    const[showAlert,setShowAlert] = useState(false);
    const [notificationTitle,setNotificationTitle] = useState(""); 
    const [notificationImage,setNotificationImage] = useState(""); 
    const [notificationGuid,setNotificationGuid] = useState(""); 
    const [appName,setAppName] = useState(""); 
    const [searchParams] = useSearchParams();
    const [notificationAlreadySendWarning,setNotificationAlreadySendWarning] = useState(false)
    const [alertMsg,setAlertMsg] = useState("");
    const [validUrl,setValidUrl] = useState(true)
    const [topic,setTopic] = useState("")
    const [projectId,setProjectId] = useState("");
    const [alertColor,setAlertColor] = useState("success");
    const [notificationSentTime,setNotificationSentTime] = useState("success");
    const [webLogo, setWebLogo] = useState("");
    const [isClicked,setIsClicked] = useState(false);

    useEffect(()=>{
    
      credentials  = firebaseCredentials[searchParams.get('pub_id')];
      let firebaseConfig;
      if(credentials===undefined){
        console.log("invalid url");
        setValidUrl(false)
        return;
      }
      setProjectId(credentials.projectId)
      firebaseConfig = {
        apiKey: credentials.apiKey,
        authDomain: credentials.authDomain,
        projectId: credentials.projectId,
        storageBucket: credentials.storageBucket,
        messagingSenderId: credentials.messagingSenderId,
        appId: credentials.appId
      };
      let app = initializeApp(firebaseConfig);
      db =  getFirestore(app);
      getArticle(db);
    },[])
   
    async function getArticle(db) {
        const citiesCol = collection(db, 'Articles');
        const q =  query(citiesCol,orderBy("published","desc"),limit(10));
        const documentSnapshots = await getDocs(q);
        const cityList = documentSnapshots.docs.map(doc => doc.data());

        const config = collection(db, 'Config');
        const documentConfigSnapshots = await getDocs(config);
        const configData = documentConfigSnapshots.docs.map(doc => doc.data());
   
        setTopic(configData[0].notification.android_topic)
        setAppName(configData[0].notification.app_name)
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement('link');
          link.rel = 'icon';
          document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href = configData[0].notification.web_fevicon;
        setWebLogo(configData[0].notification.web_logo)
        document.title = configData[0].notification.app_name;
        const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];
  
        setLastVisible(lastVisible);
        setArticles(cityList)
      }

     

      const handleNotificationClick = async(guid,title,image)=>{
        setNotificationTitle(title)
        setNotificationImage(image)
        setNotificationGuid(guid)
        setNotificationAlreadySendWarning(false)
        const notificationCol = collection(db, 'Notification');
        const cq = query(notificationCol,where("id","==", guid));
        const documentSnapshots = await getDocs(cq);
        if(documentSnapshots.empty){
            setIsClicked(true);
        }
        else{
          const notificationData = documentSnapshots.docs.map(doc => doc.data());
          setNotificationSentTime(notificationData[0].send_at);
          setNotificationAlreadySendWarning(true)
          setIsClicked(true);
        }
       
      }

      const handlePagination = async() =>{
        const citiesCol = collection(db, 'Articles');
        const q =  query(citiesCol,orderBy("published" , "desc"),startAfter(visible),limit(10));
        const documentSnapshots = await getDocs(q);
        const cityList = documentSnapshots.docs.map(doc => doc.data());
        const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];
        console.log("last", lastVisible); 
        setLastVisible(lastVisible);          
        page++;
        setArticles(cityList)
      }
      const handlePrevious = async() =>{
        console.log("Pagination previous clicked"); 
        if(page<=0){
            return;
        }
        const citiesCol = collection(db, 'Articles');
        const q =  query(citiesCol,orderBy("published" , "desc"),endBefore(visible),limit(10));
        const documentSnapshots = await getDocs(q);
        const cityList = documentSnapshots.docs.map(doc => doc.data());
        const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];
        console.log("last", lastVisible); 
        setLastVisible(lastVisible);          
        page--;
        setArticles(cityList)
      }
  return (
    <>
    {(()=>{
      if(validUrl){
        
        
      if(articles.length===0){
        return <center>
        <ClipLoader
        
        loading={true}
       
        size={70}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </center>
      }
      else{
      return <div>
        <div>
        <div>
  <div class="row mt-3 mx-2">
    <div class="col-4">
      <div className='web_logo_container'>
      <img className='web_logo' src={webLogo} alt='logo'/>
      </div>
     
    </div>
    <div class="col-4">
      <div className='d-flex justify-content-center'>
      <h4>Notification Dashboard</h4>
      </div>
     
    </div>
    <div class="col-4">
    {showAlert && <div className='d-flex justify-content-end align-items-center'>
 <div  className={`alert alert-${alertColor} alert_width`} role="alert">
       {alertMsg}
</div >
   </div>}
    </div>
  </div>
</div>
        </div>
       
        
         <div className="card mx-2">
        <table className='table'>
                <thead>
                    <tr>
                    <th scope="col">S.no</th>
                    <th scope="col">Image</th>
                    <th scope="col">Article Title</th>
                    <th scope="col">Published Date</th>
                    <th scope="col">Modified Date</th>
                    <th scope="col">Action</th>
                    </tr>
                    
                </thead>
                {articles.map((val, key) => {
                    return (
                        <tbody key={key}>
                            <tr >
                            <td>{key+1 + (page*10)}</td>
                            <td className='image_row_width'><img className='set_image' src={val.thumbimage} alt='article'/></td>
                            <td>{val.title}</td>
                            <td> { moment(val.created_on.seconds*1000).format('Do MMM  YYYY, ddd, h:mm:ss a')}</td>
                            <td> { moment(val.modified_on.seconds*1000).format('Do MMM  YYYY, ddd, h:mm:ss a')}</td>
                            
                            <td title='Notification' className='notification_icon' onClick={()=>handleNotificationClick(val.guid,val.title,val.thumbimage)} >< IoMdNotificationsOutline /></td>
                        </tr>
                        </tbody>
                        
                    )
                })}
            </table>
            
    </div>
    <div className='d-flex justify-content-center mx-3 mt-2'>
    <div className='mx-2'>
    <button onClick={handlePrevious} type="button" class="btn btn-dark">{'< Prev'}</button>
    </div>
    <div>
    <button onClick={handlePagination} type="button" class="btn btn-dark">{'Next >'}</button>
    </div>
    </div>
      </div>
      
      }
    
    
      }
      else{
      return <PageNotFound/>
       
      }
    })()}
    {isClicked && <NotificationModal 
    setIsClicked={setIsClicked}
    db={db}
    projectId={projectId}
    notificationImage={notificationImage}
    notificationGuid={notificationGuid}
    notificationTitle={notificationTitle}
    appName={appName}
    topic={topic}
    setAlertColor={setAlertColor}
    setAlertMsg={setAlertMsg}
    setShowAlert={setShowAlert}
    notificationAlreadySendWarning={notificationAlreadySendWarning}
    notificationSentTime ={notificationSentTime}
    />
    }
            
    </>
            
  )
}

export default Home