import React,{useState} from 'react'
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import {collection,addDoc} from 'firebase/firestore/lite';
import axios from 'axios';
import moment from 'moment';

const NotificationModal = ({db,projectId,notificationImage,notificationGuid,notificationTitle,appName,topic,setAlertMsg,setAlertColor,setShowAlert,setIsClicked,notificationAlreadySendWarning,notificationSentTime}) => {
    const [open, setOpen] = useState(true);
    
  const onCloseModal = () =>{
    
    setIsClicked(false);
    setOpen(false);
  } 

  const handleNotificationSubmit = async()=>{
    onCloseModal();
    try {
      const notificationCol = collection(db, 'Notification');
     
      var res = await axios.get(`https://us-central1-${projectId}.cloudfunctions.net/sendPushNotification?guid=${notificationGuid}&title=${notificationTitle}&appname=${appName}&image=${notificationImage}&topic=${topic}&project_id=${projectId}`)
      // console.log("resposne is ," +  res.data["status"]);
      if(res.status === 200 && res.data["status"]){
        setAlertMsg("Notification Send Successfully")
      setAlertColor('success')
          setShowAlert(true)
          setTimeout(()=>{
              setShowAlert(false)
          },5000)
          let notificationObject = {
              id : notificationGuid,
              send_at : moment(Date.now()).format('Do MMM  YYYY, ddd, h:mm:ss a')
          };
      await addDoc(notificationCol,notificationObject)
      }
      else{
        setAlertMsg("Error, notification cannot be sent!")
      setAlertColor('danger')
        setShowAlert(true)
        setTimeout(()=>{
            setShowAlert(false)
        },5000)
      }
    } catch (error) {
      setAlertMsg("Error, notification cannot be sent!")
      setAlertColor('danger')
      setShowAlert(true)
      setTimeout(()=>{
          setShowAlert(false)
      },5000)
    }
   
  }

  return (
    
    <div>
  
    <Modal classNames={{modal:'customModal'}} open={open} onClose={onCloseModal} center>
 {notificationAlreadySendWarning &&
  <div className='notification_text_len'>
    <p style={{color:'red'}}>You have already sent notification for this article  on {notificationSentTime}</p>
  </div>
 }     
<h5>Send Push Notification</h5>
<div>Message</div>
<div className='notification_msg'>
    <div className='notification_msg_child border border-secondary-subtle'>
        <p className='mx-2'>{notificationTitle}</p>
    </div>
</div>
<div className='d-flex justify-content-end mx-3'>
    <div className='mx-2'>
    <button onClick={()=>{
      onCloseModal()
    }} type="button" class="btn btn-dark">Cancel</button>
    </div>
    <div>
    <button onClick={()=>{
      handleNotificationSubmit()
    }} type="button" class="btn btn-dark">{true?"Send Again":"Send"}</button>
    </div>
    </div>

</Modal>
   
</div>
  );
}

export default NotificationModal