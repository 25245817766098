import React from 'react'

const PageNotFound = () => {
  return (
    <>
    <center>
         <div className='invalid_msg'>
        <h3>404 Page Not Found</h3>
       </div>
       <div className='contact_us_container'>
        <div className='d-flex justify-content-center'>
          <div className='contact_us_text'>Contact Us <a href="mailto:helpdesk@sortd.mob"> <span className='link'></span></a> </div>
        </div>
       </div>
       </center>
    </>
  )
}

export default PageNotFound