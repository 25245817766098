const firebaseCredentials = {
"qwertyuiop":{
        apiKey: "AIzaSyB1a-Q1GJA8tzRzUo-9RQCh04Z5oPNyxFg",
        authDomain: "janam-tv-d085e.firebaseapp.com",
        projectId: "janam-tv-d085e",
        storageBucket: "janam-tv-d085e.appspot.com",
        messagingSenderId: "1076344306950",
        appId: "1:1076344306950:web:bfd8277748509794583287"
    },
    "asdfghjkl":{
    apiKey: "AIzaSyD46QKaqQn4nS88e3KpqVtxiEkl4XJOsMc",
  authDomain: "test-app-2024-a974a.firebaseapp.com",
  projectId: "test-app-2024-a974a",
  storageBucket: "test-app-2024-a974a.appspot.com",
  messagingSenderId: "315851925459",
  appId: "1:315851925459:web:af60374c43add143397126"
    }
}

module.exports = {
    firebaseCredentials,
  };